
import React from "react"
import Layout from "../components/Layout"
import { useStaticQuery, graphql } from "gatsby"
import useStyles from '../styles/generalStyles'
import PageTitle from '../components/PageTitle'
import SEO from '../components/seo'

const DigitalTransformationOverview = () => {
  const classes = useStyles()
 const query = useStaticQuery(graphql`
  {
      allMarkdownRemark(filter: {frontmatter: {id: {eq: "digitaltransformationoverview"}}}) {
        nodes {
          frontmatter {
            title
            seoDescription
            seoKeywords
            seoTitle
            imageAlt
            imageTitle
            digitalOverviewImage {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
              }
            }
          }
          html
        }
      }
    }
  `)

  const pageTitle = query.allMarkdownRemark.nodes[0].frontmatter.title
  const seoDescription = query.allMarkdownRemark.nodes[0].frontmatter.seoDescription
  const seoKeywords = query.allMarkdownRemark.nodes[0].frontmatter.seoKeywords
  const seoTitle = query.allMarkdownRemark.nodes[0].frontmatter.seoTitle
  const imageTitle = query.allMarkdownRemark.nodes[0].frontmatter.imageTitle
  const imageAlt = query.allMarkdownRemark.nodes[0].frontmatter.imageAlt
  const digitalOverviewImage = query.allMarkdownRemark.nodes[0].frontmatter.digitalOverviewImage.childImageSharp.fluid.originalImg
  const markdown = query.allMarkdownRemark.nodes[0].html
  
  return (
    <Layout>
      <SEO title={seoTitle.length !== 0 ? seoTitle : pageTitle} description={seoDescription.length !== 0 ? seoDescription : null} keywords={seoKeywords.length !== 0 ? seoKeywords : null}/>
      <div className={classes.main} dangerouslySetInnerHTML={{ __html: markdown }} />
      <img className={classes.image} alt={imageAlt} title={imageTitle} src={digitalOverviewImage}/>
    </Layout>
  )
}

export default DigitalTransformationOverview